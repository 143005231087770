import {
  BenefitsControllerService,
  PharmacyInsuranceResponse
} from "@9amhealth/openapi";
import { PharmacyInsuranceRequest } from "@9amhealth/openapi";
import reportErrorSentry from "src/lib/reportErrorSentry";
import primaryPlanOwner = PharmacyInsuranceRequest.primaryPlanOwner;
import type { TranslationKey } from "src/types/translationKey";
import { Cubit } from "blac-next";

export enum PharmacyInsuranceCardDetailsFormKey {
  rxBin = "rxBin",
  rxGrp = "rxGrp",
  cardHolderId = "cardHolderId",
  rxPcn = "rxPcn"
}

export enum AddPharmacyInsuranceDocuments {
  FRONT = "frontPhotoFileId",
  BACK = "backPhotoFileId"
}

export type CardDetailsPharmacyInsuranceFormValues = Record<
  PharmacyInsuranceCardDetailsFormKey,
  string
>;
export type CardDetailsPharmacyInsuranceFormFiles = Record<
  AddPharmacyInsuranceDocuments,
  string
>;

interface PatientInsuranceState {
  currentPrimaryPharmacyInsurance?: PharmacyInsuranceResponse;
  pharmacyInsurances?: PharmacyInsuranceResponse[];
}
export default class PatientPharmacyInsuranceBloc extends Cubit<PatientInsuranceState> {
  addInsuranceFormFields: {
    name: PharmacyInsuranceCardDetailsFormKey;
    label: TranslationKey;
    placeholder?: string;
  }[] = [
    {
      name: PharmacyInsuranceCardDetailsFormKey.cardHolderId,
      label: "cardholderId",
      placeholder: "91B5FE80"
    },
    {
      name: PharmacyInsuranceCardDetailsFormKey.rxBin,
      label: "insuranceNumber.label_rxBinIin",
      placeholder: "004336"
    },
    {
      name: PharmacyInsuranceCardDetailsFormKey.rxGrp,
      label: "insuranceNumber.label_rxGroup",
      placeholder: "RX0708"
    },
    {
      name: PharmacyInsuranceCardDetailsFormKey.rxPcn,
      label: "insuranceNumber.label_rxPcn",
      placeholder: "ADV"
    }
  ];

  temporaryCardDetailFormValues: {
    current: CardDetailsPharmacyInsuranceFormValues | null;
  } = {
    current: null
  };
  temporaryCardDetailFiles: {
    current: CardDetailsPharmacyInsuranceFormFiles | null;
  } = {
    current: null
  };

  /*
   * if insuranceId exists, it means the insurance is already created and we are updating it
   */
  primaryInsuranceId: string | undefined;

  constructor() {
    super({});
  }

  /**
   * Load the user's insurances from the API
   */
  public loadInsurances = async () => {
    try {
      const response = await BenefitsControllerService.getBenefits();

      const insurances = response.data.pharmacyInsurances ?? [];

      // sort, primary first, then alphabetically
      insurances.sort((a, b) => {
        if (a.isPrimary && !b.isPrimary) {
          return -1;
        }
        if (!a.isPrimary && b.isPrimary) {
          return 1;
        }
        return a.cardHolderId.localeCompare(b.cardHolderId);
      });

      const primary = insurances.find((insurance) => insurance.isPrimary);

      this.temporaryCardDetailFormValues.current = {
        rxBin: primary?.rxBin ?? "",
        rxGrp: primary?.rxGrp ?? "",
        cardHolderId: primary?.cardHolderId ?? "",
        rxPcn: primary?.rxPcn ?? ""
      };

      this.temporaryCardDetailFiles.current = {
        [AddPharmacyInsuranceDocuments.FRONT]: primary?.frontPhotoFileId ?? "",
        [AddPharmacyInsuranceDocuments.BACK]: primary?.backPhotoFileId ?? ""
      };

      this.primaryInsuranceId = primary?.id;

      this.patch({
        currentPrimaryPharmacyInsurance: primary,
        pharmacyInsurances: insurances
      });
    } catch (error) {
      reportErrorSentry(error);
    }
  };

  /**
   * Add a new card to the user's account, updates the state with the new card
   * @param fields
   * @param files
   */
  public savePharmacyInsurance = async (
    fields: CardDetailsPharmacyInsuranceFormValues,
    files: CardDetailsPharmacyInsuranceFormFiles
  ) => {
    const pharmacyInsuranceRequest: PharmacyInsuranceRequest = {
      cardHolderId: fields[PharmacyInsuranceCardDetailsFormKey.cardHolderId],
      rxBin: fields[PharmacyInsuranceCardDetailsFormKey.rxBin],
      rxGrp: fields[PharmacyInsuranceCardDetailsFormKey.rxGrp],
      rxPcn: fields[PharmacyInsuranceCardDetailsFormKey.rxPcn],
      frontPhotoFileId: files[AddPharmacyInsuranceDocuments.FRONT],
      backPhotoFileId: files[AddPharmacyInsuranceDocuments.BACK],
      status: PharmacyInsuranceRequest.status.ACTIVE,
      isPrimary: true,
      primaryPlanOwner: primaryPlanOwner.SELF
    };

    const dontCheckFields: (keyof typeof pharmacyInsuranceRequest)[] = [
      "status",
      "isPrimary",
      "primaryPlanOwner"
    ];

    // verify that all fields are filled out, check if any fields in pharmacyInsuranceRequest are missing
    const missingFields = Object.entries(pharmacyInsuranceRequest).filter(
      ([key, value]) =>
        !dontCheckFields.includes(
          key as keyof typeof pharmacyInsuranceRequest
        ) && !value
    );

    if (missingFields.length > 0) {
      throw new Error(
        `Missing fields: ${missingFields.map(([key]) => key).join(", ")}`
      );
    }

    try {
      if (this.primaryInsuranceId) {
        await BenefitsControllerService.updatePharmacyInsurance(
          this.primaryInsuranceId,
          pharmacyInsuranceRequest
        );

        return;
      }

      await BenefitsControllerService.createPharmacyInsurance(
        pharmacyInsuranceRequest
      );
    } catch (error) {
      reportErrorSentry(error);
      throw error;
    }
  };
}
